import React from 'react'
import Translations from '@helpers/translations'
import Stars from '@components/ui/Stars'
import styles from './reviewItemStyles.module.scss'
import { ButtonLink } from '@components/ui/Button'

export type ReviewItemProps = {
  title: string
  description: string
  author: string
  stars: number
  location?: string
  buttonUrl?: string
  buttonTitle?: string
}

function ReviewItem({
  title,
  description,
  stars,
  author,
  location,
  buttonUrl,
  buttonTitle
}: ReviewItemProps) {
  return (
    <article className={styles.ReviewItem}>
      <div className={styles.ReviewItem__copy}>
        {title && <h3 className={styles.ReviewItem__title}>{title}</h3>}
        <p className={styles.ReviewItem__description}>{description}</p>
      </div>

      <footer className={styles.ReviewItem__author}>
        <Stars value={stars} color="color2" />
        <p>
          <span>{author}</span>
          {location
            ? ` ${Translations.localise('common.reviews.from')} ${location}`
            : ''}
        </p>
        {buttonUrl && (
          <ButtonLink
            href={buttonUrl}
            classes={styles.ReviewItem__button}
            variant="secondary"
          >
            {buttonTitle}
          </ButtonLink>
        )}
      </footer>
    </article>
  )
}

export default ReviewItem
