import React from 'react'
import { getKey } from '@helpers/env'
import colours from './colours.module.scss'
import styles from './styles.module.scss'

function SVG({ children }: { children: React.ReactElement }) {
  return (
    <div className={styles.starIcon}>
      <svg
        id="star-icon"
        data-name="star-icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 23 23"
        width="13"
        height="13"
      >
        {children}
      </svg>
    </div>
  )
}

const getStarRating = (score: number) => {
  if (score >= 1) return 1
  if (score < 1 && score > 0) return 0.5
  return 0
}

type StarsProps = {
  value: number
  color?: 'color1' | 'color2'
}

function Stars({ value = 0, color = 'color1' }: StarsProps) {
  const stars = [1, 2, 3, 4, 5].map((star, index) =>
    getStarRating(value - index)
  )
  const starColor = colours[color]
  return (
    <div className={styles.stars} aria-label={`Rating of ${value} out of 5.`}>
      {stars.map(star => {
        if (star === 1) {
          return (
            <SVG key={getKey(`star-${star}`)}>
              <polygon
                fill={starColor}
                points="23,8.8 15,7.6 11.5,0 8,7.6 0,8.8 5.7,14.7 4.4,23 11.5,19 18.6,23 17.2,14.7"
              />
            </SVG>
          )
        }
        if (star === 0) {
          return (
            <SVG key={getKey(`star-${star}`)}>
              <polygon
                stroke={starColor}
                fill="none"
                points="23,8.8 15,7.6 11.5,0 8,7.6 0,8.8 5.7,14.7 4.4,23 11.5,19 18.6,23 17.2,14.7"
              />
            </SVG>
          )
        }
        return (
          <SVG key={getKey(`star-${star}`)}>
            <path
              fill={starColor}
              stroke={starColor}
              d="M23,8.8l-8-1.21L11.51,0,8,7.59,0,8.8l5.75,5.9L4.4,23l7.11-3.93L18.62,23,17.26,14.7ZM17.3,21.15,12,18.22,11.51,18h0V2.37L14.16,8l.23.48.52.08,6,.92L16.55,14l-.36.36.08.5Z"
              transform="translate(-.01 -.02)"
            />
          </SVG>
        )
      })}
    </div>
  )
}

export default Stars
