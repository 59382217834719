import React from 'react'
import Carousel, { CarouselSlide } from '@components/ui/Carousel'
import ReviewItem, { ReviewItemProps } from './ReviewItem'
import { ReactComponent as QuoteMarksIcon } from '@svgs/quote-marks.svg'
import styles from './styles.module.scss'
import SectionHeadline from '../SectionHeadline'

type ReviewsProps = {
  title: string | null
  reviews: {
    [key: number]: ReviewItemProps
  }
}

export default function Reviews({ title, reviews }: ReviewsProps) {
  const reviewsList = Object.values(reviews)
  return (
    <>
      {!!reviewsList?.length && (
        <section className={styles.Reviews}>
          {!!title && (
            <SectionHeadline
              title={title}
              className={styles.sectionHeader}
              showBorder={true}
            />
          )}
          <div className={styles.Reviews__wrapper}>
            <QuoteMarksIcon className={styles.QuoteMarks} />

            <div className={styles.Reviews__carousel}>
              <Carousel
                settings={{
                  dots: true,
                  buttons: true,
                  buttonsVariant: 'secondary',
                  loop: true,
                  plugins: {
                    fade: true,
                    autoHeight: false
                  }
                }}
              >
                {reviewsList.map(review => (
                  <CarouselSlide
                    key={`review-${review.author}-${review.stars}`}
                  >
                    <ReviewItem {...review} />
                  </CarouselSlide>
                ))}
              </Carousel>
            </div>
          </div>
        </section>
      )}
    </>
  )
}
